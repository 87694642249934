import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewPage from '../components/Height_adjustable_desk'


const NewPages = () => (
  <Layout>
    <SEO title="Height_adjustable_desk" />
<NewPage/>
  </Layout>
)

export default NewPages