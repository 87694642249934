import React from 'react'
import contr1 from '../../images/AustenTable/ipwith4memory.png'
import contr2 from '../../images/AustenTable/updowntouchhead.png'
import styled from 'styled-components'

export default function TouchBtn() {
    return (
        <TouchbtnWrap className="container my-4 text-center mb-2"><br/>
            <h1 className="text-center text-uppercase mb-4"> Touch Button Controllers </h1>
            <div className="row">
                <div className="col d-flex justify-content-center mb-4 mb-sm-0" style={ { borderRight:"3px solid brown"}}>
                    <div className="">
                <img height="250px" width="400px"  src={contr1} alt="Controller 1"/>
                <h4> 4 Memory buttons Touch FXHand Controller<br/> ( For Steelforce 470 SLS model Only ) </h4>
                </div></div>
                <div className="col d-flex justify-content-center">
                    <div>
                <img  height="250px" width="400px" src={contr2} alt="Controller 1"/>
                <h4> Up Down Touch Hand Controller<br/> ( For Steelforce 300 and 370 SLS model Only ) </h4>
                </div>
                </div>
            </div><br/>
        </TouchbtnWrap>
    )
}
const TouchbtnWrap=styled.div`
@media(max-width:960px){

    h4{
        font-size: 1rem;
    }
}

`