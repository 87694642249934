import React from 'react'
import Table from './table'
import Video from './video'
import Sec from './sec'
import Models from './models'
import PImg from './product'
import Controller from './touchBtn'
import Banner from './banner'
export default function index() {
    return (
        <div>
            <Video/>
            <Sec/>
            <Models/>
            <Controller />
            <Banner/> 
            <PImg/>
        <Table/>
        </div>
    )
}
