import React from 'react'
import imgp1 from '../../images/AustenTable/newpage/product/imgp (1).png'
import imgp2 from '../../images/AustenTable/newpage/product/imgp (2).png'
import imgp3 from '../../images/AustenTable/newpage/product/imgp (3).png'
import imgp4 from '../../images/AustenTable/newpage/product/imgp (4).png'
import imgp5 from '../../images/AustenTable/newpage/product/imgp (5).png'
import imgp6 from '../../images/AustenTable/newpage/product/imgp (6).png'

import { Carousel } from 'react-responsive-carousel';
export default function Product() {
    const imgData = [
        {imgp:imgp5,
        title:`Safe Drawer with Felt Mat`,
        price:`RS 1,848`
},
        {imgp:imgp2,
        title:`Electric Box – Ceiling Mount 
        (2 Power + 2 USB Charger Sockets)`,
        price:`Rs 4,000 `
},
        {imgp:imgp6,
        title:`Wireless Charger
        `,
        price:`RS 3,378`
},
        {imgp:imgp3,
        title:`Undermount Cable Tray
        `,
        price:`Rs 1,068`
},
        {imgp:imgp4,
        title:` Rectangular Vertebrae 1580mm   `,
        price:`Rs 892`
},
         {imgp:imgp1,
        title:`Footrest with Tilt Mechanism
        `,
         price:`Rs 1,600`
}
    ]
    return (
        <div className="container ">
            <br/>
<h2 className="text-uppercase text-center text-secondary">Additional Accessories</h2>
            <div className="row d-sm-flex d-none">
{
    imgData.map((item, i)=>{
        return <div className="col-sm-4 p-2" key={i}>
            <img className="d-block mx-auto" src={item.imgp} alt="imgitem" width="100%" height="340px" /> 
            <h4 className="text-center text-danger mb-0">{item.title}</h4>
            <h4 className="text-center mt-1 ">{item.price}</h4>
        </div>
    })
}
            </div>
<div className="d-block d-sm-none" style={{ border:"1px solid #00000061", borderRadius:"20px", overflow:"hidden"}}>
            <Carousel showArrows={true} showThumbs={false} 
                                   autoPlay={ true  } 
                                   interval={5000}
                                   showIndicators={false}
                                   infiniteLoop={true} 
                                    >
{
imgData.map((item, i)=>{
    return <div className=" p-0" key={i}>
        <img className="d-block mx-auto" src={item.imgp} alt="imgitem" width="100%" height="340px" /> 
        <h4 className="text-center text-danger mb-0">{item.title}</h4>
        <h4 className="text-center mt-1 ">{item.price}</h4>
    </div>
})
}
                                       </Carousel>
                                       </div>
        </div>
    )
}