import React from 'react'
import Steelforce from '../../images/AustenTable/newpage/4.png'
import styled from 'styled-components'
export default function Sec() {
    return (
        <SecWrap className="container mt-0 my-sm-5">
        <div className="row">
            <div className="col-sm-7">

                <h2 className="text-dark mt-3">MOVEMENT <br/> BEGINS <br/> HERE!</h2>

            </div>
            <div className="col-sm-5 px-0">
                <img src={Steelforce} width="100%" height="100%" alt="asten"/>
            </div>

            

        </div>
        </SecWrap>
    )
}

const SecWrap = styled.div`

background: linear-gradient(138deg,rgba(232,192,29,1) 32%,rgba(255,255,255,1) 32%);

h2{
    transform: translateX(-10%);
    font-size: 5rem;
font-weight:bold;

}

@media(max-width:960px){

    h2{

      transform: translateX(1%);

  font-size: 3rem;  
}   
    }
   
`