import React, { useState } from 'react'
import img1 from '../../images/AustenTable/newpage/1.png'
import img2 from '../../images/AustenTable/newpage/2.png'
import img3 from '../../images/AustenTable/newpage/3.png'
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components'
import imgdw1 from '../../images/shop/steel.jpg'
import imgd1 from '../../images/AustenTable/newpage/o (1).png'

import imgdw2 from '../../images/shop/steel2.png'
import imgd2 from '../../images/AustenTable/newpage/o (2).png'

import imgdw3 from '../../images/shop/steel3.png'
import imgd3 from '../../images/AustenTable/newpage/o (3).png'

import contr1 from '../../images/AustenTable/ipwith4memory.png'
import contr2 from '../../images/AustenTable/updowntouchhead.png'

import img9 from '../../images/AustenTable/newpage/9.png'


export default function Models() {

    const [data, setData] = useState({name:'STEELFORCE PRO 300 SLS', price:'₹27,250', gal:[imgd1, imgd2], cimg:contr1})
    const ModelsData = [
        {
            name:`STEELFORCE 300 SLS`,
            desc:`2 step, updown touch Hand Controller`,
            price:`₹27,250`,
            img:img9,
            galery:[img9, imgdw1],
            cimg:contr1,
        },
        {
            name:`STEELFORCE PRO 370 SLS`,
            desc:`2 step, updown touch Hand Controller`,
            price:`₹31,462`,
            img:img2,
            galery:[imgd2, imgdw2],
            cimg:contr1,
        },
        {
            name:`STEELFORCE PRO 470 SLS`,
            desc:`3 step, updown touch Hand Controller`,
            price:`₹37,187`,
            img:img3,
            galery:[imgd3,  imgdw3],
            cimg:contr2,
        },
    ]

    console.log(data.gal);
    return (
        <ModelWrap className="container">
<div className="text-center my-3 my-sm-4 ">
    <h1 style={{fontWeight:500}} className="text-danger mb-2">OUR MODELS</h1>
    <h2  style={{fontWeight:600}} className="text-secondary subtitle mt-2">{'Actiforce Electrical Height Adjustable Sit Stand Desks'}</h2>
    <h2  style={{fontWeight:600}} className="text-secondary subtitle mt-2">{'German Engineering combined with Dutch & Danish Design'}</h2>
    <h2  style={{fontWeight:600}} className="text-secondary subtitle mt-2">{'Bosch Dual Motors'}</h2><br/>
</div>

            <div className="row">
                {

                    ModelsData.map((item, i)=>{

                        return  <div  className="col-sm-4 cimg p-1 mb-4" style={{cursor:"pointer"}} key={i} onClick={()=>setData({name:item.name, price:item.price, gal:item.galery, cimg:item.cimg})}>
                         <img  src={item.img} alt={item.name}/>
                          <h3 className="text-center text-secondary mb-1">{item.name}</h3>
                          <p className="text-center">{item.desc}</p>
                          <div className="buybtn">
                          <button className="bg-success text-light border border-success px-4 py-sm-2 py-2 ">{item.price}</button>
                          <a href="https://wa.me/918971181818" target="_tab">
                          <button className="bg-white text-success border border-success px-3 py-sm-2 py-2">Order now</button></a>
                          </div>
                    </div>
                    })
                   
                }
            </div>
             {/* <div className="row mt-5">
            <div className="col-sm-7 bg-dark px-0 model" style={{ overflow:"hidden"}}>
<Carousel showArrows={false} showThumbs={false} 
autoPlay={data.gal.length>0 ? true : false } 
infiniteLoop={data.gal.length>0 ? true : false } >
              { data.gal.map((item, i)=>{

              return <div key={i}>
                    <img src={item} className="rounded shadow" alt="item" style={{height:"100%"}} />
                </div>

              }) }
               
            </Carousel>          
            </div>
            <div className="col-sm-5 mt-3 mt-sm-0">

  
           <h2 className="text-danger">MODEL</h2>
           <h1 className="mb-0" style={{fontSize:"2.5rem"}}>{data.name}</h1>
           <h3 className="mt-1" style={{fontWeight:400, lineHeight:1.7}}>2 step, sit stand Height Electricaly Adjustable Desk <br/> 
           with updown touch Hand Controller </h3>

           <h2 className="text-danger">{data.price} <small className="text-dark ml-3">{'(inclusive of all taxes)'}</small></h2>
           
           <img src={data.cimg} height="200px" width="320px" className="rounded " alt="item" />

            </div>

            </div> */}
        </ModelWrap>
    )
}


const ModelWrap = styled.div`
.buybtn{
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        border: none;
        background-color: #E8C01D;
        border-radius: 10px;
        margin: 0 10px;

    }
}
.cimg {

transition: 8s;
&:hover {
    overflow: hidden;


img{
   box-shadow: 0 2px 10px 4px #e5e5e5; 
   transform: scale(1.01);

}


}

}

.model{

    height: 450px;
}



@media(max-width:960px){

    .subtitle{

font-size: 14px;
line-height: 1.5;
margin-bottom: 0;

}
    .cimg {

     img{

         width:100%;
     }   
    }

    .model{

height: 100%;
}
}

`