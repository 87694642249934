import React, { Component } from 'react';
import styled from 'styled-components';
import video from '../../images/Height Adjustable Desk-1.mp4'
import videomb from '../../images/actiforce mobile potrait.mp4'
import mobvimg from '../../images/adjustdesk.png'
import Dimg from '../../images/adjustdesk.png'

class Video extends Component {
  render() {
    return (
      <VideoWrapper>
        <video playsInline webkit-playsInline   disablePictureInPicture="true" preload="auto" crossorigin="anonymous" autoPlay muted loop id="myVideo" width="100%">
  <source poster={Dimg} src={video} type="video/mp4"/>
  Your browser does not support HTML5 video.
</video>

<div class="m-0 p-0">
<video  poster={mobvimg} playsInline webkit-playsInline preload="auto" disablePictureInPicture="true"  autoPlay muted loop id="mymb" width="100%">
  <source src={videomb}type="video/mp4"/>
  Your browser does not support HTML5 video.
</video>
  </div>      
<div id="scrollbtn" >

  <a href="#txt" class="scroll-down" address="true"></a>
  <small className="text-center text-white">scroll down</small>
</div>
      </VideoWrapper>
    );
  }
}

const VideoWrapper = styled.div`
  overflow:hidden;
 background-color:black;
  z-index:-1;
box-shadow:1px -101px 50px 40px black;
 #scrollbtn{

  z-index:21;
  position:absolute;
  bottom:-10%;
  left:50%;
  transform:translate(-50%, -50%);

 }
#myVideo{


display:block;
}

#mymb{

display:none;
    height:100vh !important;

}

@media (max-width:1508px){

  #scrollbtn{

z-index:21;
position:absolute;
bottom:10%;
left:50%;
transform:translate(-50%, -50%);

}



}

@media (max-width:768px){

  margin-top:0px;


}

@media (max-width:960px){

  #myVideo{


display:none;
}

  #mymb{

display:block;
    height:58vh !important;

}

}


.scroll-down {
  opacity: 1;
  -webkit-transition: all .5s ease-in 3s;
  transition: all .5s ease-in 3s;
}

.scroll-down {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -16px;
  display: block;
  width: 32px;
  height: 32px;
  border: 2px solid #FFF;
  background-size: 14px auto;
  border-radius: 50%;
  z-index: 2;
  -webkit-animation: bounce 2s infinite 2s;
  animation: bounce 2s infinite 2s;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  transform: scale(1)
}

.scroll-down:before {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 6px);
    transform: rotate(-45deg);
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    border: 2px solid white;
    border-width: 0px 0 2px 2px;
}






@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}











`
export default Video;