import React from 'react'
import styled from 'styled-components'
import bannerimg from '../../images/AustenTable/newpage/banner (2).png'
export default function Banner() {
    return (
        <BannerWrap className="container bg-dark">
            <div className="row">
                <div className="col-sm-7 p-0">

               <img src={bannerimg} alt="banner" width="100%"  />
                </div>
                <div className="col-sm-5 d-sm-flex justify-content-center align-items-center banner ">
                   
                    <div>
                    <h2 className="dview">Upgrade to <br/> custom made ergonomic <br/> curved table top</h2>
                    <h2 className="dmb">Upgrade to <br/> custom made ergonomic curved table top</h2>
                    <a href="https://wa.me/918971181818Call" target="_tab">
                    <button className="bg-white text-dark px-5 py-sm-3 py-2"> Enquire now</button></a>
                        </div>
                </div>
                 
            </div>
   
        </BannerWrap>
    )
}


const BannerWrap = styled.div`
.dview{
display: block;

}

.dmb{

display: none;

}

.banner {

    h2{

        font-size: 2.5rem;
        color:white;
    }
    button {

        border-radius: 20px;
        border:none;
        font-size: 1.2rem;
        font-weight: 600;

    }
}

@media(max-width:960px){

.dview{

    display: none;

}

.dmb{

display: block;

}
    .banner {

padding: 1rem;

        h2{

font-size: 1.5rem;
color:white;
}

button {

border-radius: 20px;
border:none;
font-size: 1rem;
font-weight: 600;
text-align: center;


}

     }
}

`