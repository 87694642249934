import React from 'react'
import styled from 'styled-components'
import { BsCheck } from 'react-icons/bs'
import { AiOutlineClose } from 'react-icons/ai'

export default function table() {


    const tableData = [
        {
            feature:"No of motors (Bosch Make)",
            steelforce:"Dual",
            stforce370:"Dual",
            stforce470:"Dual"

        },
        {
            feature:"No of steps",
            steelforce:"2 steps",
            stforce370:"2 steps",
            stforce470:"3 steps"
        },    {
            feature:"Height Adjustability (mm)",
            steelforce:"690-1180",
            stforce370:"690-1180",
            stforce470:"615-1275"
        },    {
            feature:"Width Adjustability (mm)",
            steelforce:"1000-1500",
            stforce370:"1100-1700",
            stforce470:"1100-1700"
        },    {
            feature:"Soft touch Up and Down Buttons",
            steelforce:<BsCheck/>,
            stforce370:<BsCheck/>,
            stforce470:<BsCheck/>
        },    {
            feature:"4 memory slots",
            steelforce:<AiOutlineClose className="no" />,
            stforce370:<AiOutlineClose className="no" />,
            stforce470:<BsCheck/>
        },    {
            feature:"Column Size (mm) ",
            steelforce:"60 X 60",
            stforce370:"70 X 70",
            stforce470:"70 X 70"
        },    {
            feature:"Table Frame/ Legs color",
            steelforce:"Pearl White, Black Sand",
            stforce370:"Pearl White, Black Sand",
            stforce470:"Pearl White"
        }, {
            feature:"Default Table Top",
            steelforce:"White Rectangular",
            stforce370:"White Rectangular",
            stforce470:"White Rectangular"
        },    {
            feature:"Speed (mm/s)",
            steelforce:"39",
            stforce370:"39",
            stforce470:"39"
        },    {
            feature:"Noise db(A)",
            steelforce:"<55",
            stforce370:"<55",
            stforce470:"<55"
        },    {
            feature:"Power",
            steelforce:"V 220-240",
            stforce370:"V 220-240",
            stforce470:"V 220-240"
        },    {
            feature:"Low Energy Consumption",
            steelforce:<BsCheck/>,
            stforce370:<BsCheck/>,
            stforce470:<BsCheck/>
        },    {
            feature:"Spindle Lifting System",
            steelforce:<BsCheck/>,
            stforce370:<BsCheck/>,
            stforce470:<BsCheck/>
        },    {
            feature:"Powder Coated Steel Legs, Feet and Top",
            steelforce:<BsCheck/>,
            stforce370:<BsCheck/>,
            stforce470:<BsCheck/>
        },    {
            feature:"Galvanized Steel Crossbar",
            steelforce:<BsCheck/>,
            stforce370:<BsCheck/>,
            stforce470:<BsCheck/>
        },    {
            feature:"Wireless Charger (Qi certified 10W fast charging)",
            steelforce:"Add On",
            stforce370:"Add On",
            stforce470:"Add On"
        },    {
            feature:"Undermount Cable Tray",
            steelforce:"Add On",
            stforce370:"Add On",
            stforce470:"Add On"
        },
    
    ]


    return (
        <TableWrap className="container px-0  my-5">
          
          <table className="table table-striped ">
  <thead>
    <tr className="py-5" >

      <th scope="col" className="text-dark text-left" style={{fontWeight:"bold"}}>Features</th>
      <th scope="col"  className="text-secondary" style={{fontWeight:"600"}} >Steelforce 300</th>
       <th scope="col" className="text-secondary" style={{fontWeight:"600"}} >Steelforce Pro SLS 370</th>
      <th scope="col" className="text-secondary" style={{fontWeight:"600"}} >Steelforce Pro SLS 470</th>

   </tr>
  </thead>
  <tbody>

      {
          tableData.map((item, i)=>{

          return   <tr key={i}>
          <td className="text-left "  style={{fontWeight:"600",  color:"rgb(90 90 90)"}}>{item.feature}</td>
          <td className="text-secondary">{item.steelforce}</td>
                <td className="text-secondary">{item.stforce370}</td>
          <td className="text-secondary">{item.stforce470}</td>

        </tr>

          })
      }
  
    
  </tbody>
</table>

        </TableWrap>
    )
}

const TableWrap = styled.div`
.table{

    border:1px solid #e4e3e3;

    svg {

        color: #0DA079;
        background-color: #DEF0EE;
        border-radius: 50%;
      width:30px;
      height:30px;
      padding:0.3rem;
      font-weight: bold;

    
    }

    .no {

color: #DC3545 !important;
background-color: #FBEAEC !important;
}
}
.table td, .table th{

    padding:1.7rem 0 !important;
    font-family:'Poppins', sans-serif !important;
    font-size: 1.2rem;
    letter-spacing:0.2px;
    text-align:center ;
    padding-left: 0.4rem !important;

}

.table th{

    border-bottom: none !important;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F7F9FC !important;
}

@media(max-width:760px){

    .table td, .table th{

        font-size:0.8rem;
    }

}

`